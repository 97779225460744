import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import Promise from 'bluebird';

require('./utils');

window.Promise = Promise;
window.$ = window.jQuery = $;
window._ = _;
window.moment = moment;
window.title = document.title;
window.enums = [];

window.getLocalizationSourceName = () => {
    let pathnames = location.pathname.split('/');

    pathnames = pathnames.filter(p => {
        return p && p.toLowerCase() != "index" && p.toLowerCase() != "create" && p.toLowerCase() != "update" && !$.isNumeric(p);
    });

    return pathnames.length > 0 ? _.join(pathnames, "") : "ProSante";
};
window.localizationSourceName = window.localizationSourceName || getLocalizationSourceName();

window.L = (key, opts) => {
    let source, prepend = '', append = '', format = '';

    if (_.isString(opts))
        source = opts;
    else if (_.isObject(opts)) {
        prepend = opts.prepend || '';
        append = opts.append || '';
        format = opts.format || '';
        source = opts.source || localizationSourceName;
    }

    source = source || localizationSourceName;

    var string = abp.localization.values[source] ? abp.localization.values[source][key] : "";
    string = string || abp.localization.values["ProSante"][key];
    string = string && format ? string.format(format) : string;
    string = string || '[' + key + ']';

    string = prepend + string + append;

    return string;
};

window.busy = (element, promise) => {
    promise ? abp.ui.setBusy($(element ? element : "body"), promise) : abp.ui.setBusy($(element ? element : "body"));
};

window.clearBusy = (element) => {
    abp.ui.clearBusy(element ? element : "body");
};

window.selectLocalize = (list) => {
    return _.map(list, o => {
        return { ...o, text: L(o.text) };
    });
};

window.enumToSelect = (myEnum, opts, prepend) => {
    return _.map(_.keys(myEnum), e => {
        let key = prepend ? `${prepend}${e}` : abp.utils.toPascalCase(`${e}`);
        return { key, text: L(key, opts), value: myEnum[e] };
    });
};

window.getTextEnum = (myEnum, opts, prepend, value) => {
    let key = _.invert(myEnum)[ko.utils.unwrapObservable(value)];

    if (key) {
        key = prepend ? `${prepend}${key}` : abp.utils.toPascalCase(`${key}`);
        return L(key, opts);
    }

    return '';
};

window.scrollToElement = (element) => {
    $("html, body").animate({
        scrollTop: $(element).position().top
    }, "slow");
};

window.removeDuplicateOptionSelect2 = (tag) => {
    tag = tag + ' option';

    $(tag).each(function() {
        if ($(tag + '[value=' + $(this).val() + ']').length > 1)
            $(this).remove();
    });
};

window.swalConfirm = (title, confirmFunc, cancelFunc) => {
    swal({
        title: title,
        type: 'question',
        showCancelButton: true,
        showLoaderOnConfirm: true
    }).then(() => { return confirmFunc(); }, (isconfirm) => {
        if (isconfirm === 'cancel' && typeof cancelFunc === 'function')
            cancelFunc();
    });
};

window.isEmpty = (value) => {
    return value === null || value === undefined || value.length === 0 || isNaN(value);
};

window.hasDuplicate = (list, fields) => {
    let fieldsIsArray = Array.isArray(fields);

    if (!list || list.length < 2)
        return false;

    return list.length !== _.uniqBy(list, l => {
        if (!fieldsIsArray)
            return ko.utils.unwrapObservable(l[fields]);
        else
            return [_.map(fields, f => ko.utils.unwrapObservable(l[f]))].join();
    }).length;
};

function htmlSetTargetBlank(str) {
    return (str || '').replace(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g, '$& target="blank"');
}

window.htmlSetTargetBlank = htmlSetTargetBlank;

$.fn.outerHtml = function () {
    return $('<div />').append(this.eq(0).clone()).html();
};

require('icheck');
require('eonasdan-bootstrap-datetimepicker');
require('jquery.maskedinput/src/jquery.maskedinput');
require('jQuery.print/jQuery.print');
require('jspdf');