window.htmlentities = window.htmlentities ||
{
    encode: function (str) {
        var buf = [];

        for (var i = str.length - 1; i >= 0; i--)
            buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));

        return buf.join('');
    },
    decode: function (str) {
        return str.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
    },
    stripHtml: function(html){
        var tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;

        return tempDiv.textContent || tempDiv.innerText || "";
    }
};